<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Toasts">
                    <template v-slot:description>
                        <div>import { HbToast, HbToastItem } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Mini-Charm Prototype Example - HbToast + Code
            </template>

            <div class="ma-4">
                
                <v-row no-gutters>
                    <v-col cols="3" no-gutters>
                        <hb-btn @click="show = !show" :color="show ? 'destructive' : 'primary'">{{ show ? 'Close Toast' : 'Show Toast'}}</hb-btn>
                        <div v-if="type == 'mini-charm' && show" style="height: 20px;"></div>
                        <hb-btn color="destructive" v-if="type == 'mini-charm' && incoming_notifications.length && show" @click="incoming_notifications = []">Clear Incoming Calls</hb-btn>
                    </v-col>

                    <v-col cols="3" no-gutters v-if="type == 'mini-charm' && show" class="mb-2">
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('call in')" :disabled="addingIncomingNotificationInProgress">Add Incoming Call</hb-btn>
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('active')" style="position: relative;top: 4px;" :disabled="addingIncomingNotificationInProgress || active_call_id.length > 0">Add Incoming Active Call</hb-btn>
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('hold')" style="position: relative;top: 8px;" :disabled="addingIncomingNotificationInProgress">Add Incoming Hold Call</hb-btn>
                    </v-col>

                    <v-col cols="3" no-gutters v-if="type == 'mini-charm' && show" class="mb-2">
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('missed')" :disabled="addingIncomingNotificationInProgress">Add Incoming Missed Call</hb-btn>
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('reservation')" style="position: relative;top: 4px;" :disabled="addingIncomingNotificationInProgress">Add Incoming Reservation</hb-btn>
                        <hb-btn small color="secondary" width="200" @click="addIncomingNotification('voicemail')" style="position :relative;top: 8px;" :disabled="addingIncomingNotificationInProgress">Add Incoming Voicemail</hb-btn>
                    </v-col>

                </v-row>

                <v-row no gutters>

                    <v-col cols="6" no-gutters>
                        <hb-card class="mt-4" no-title width="552">

                            <hb-form label="type">
                                <HbSelect
                                    v-model="type"
                                    placeholder="Select Type"
                                    :items="typeItems"
                                    :clearable="false"
                                />
                            </hb-form>

                            <hb-form v-if="lastEmittedEvent" label="Last Emitted Event">
                                {{ lastEmittedEvent }}
                            </hb-form>

                            <hb-form label="loading" v-if="view == 'default'">
                                <HbSwitch
                                    v-model="loading"
                                    :label="loading == true ? 'true' : loading ? loading : 'null'"
                                />
                            </hb-form>

                            <hb-form v-if="type !== 'communication'" label="view">
                                <HbSelect
                                    v-model="view"
                                    :items="viewItems"
                                    placeholder="Select View"
                                    :disabled="type == 'mini-charm'"
                                    :clearable="false"
                                />
                            </hb-form>

                            <hb-form v-if="type !== 'communication' && type !== 'missed calls' && type !== 'voicemails' && type !=='reservations' && view !== 'list'" label="status">
                                <HbSelect
                                    v-model="notification.status"
                                    :items="statusItems"
                                    placeholder="Select Status"
                                    :clearable="type == 'mini-charm' ? false : true"
                                    @change="checkManualStatusOverride()"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list'" label="communication-type">
                                <HbSelect
                                    v-model="notification.communication_type"
                                    :items="communicationTypeItems"
                                    placeholder="Select Communication Type"
                                    :clearable="false"
                                />
                            </hb-form>

                            <hb-form v-if="view == 'list' && type !== 'communication'" label="count">
                                <HbAutocomplete
                                    v-model="count"
                                    :items="countItems"
                                    placeholder="Select Count"
                                    :clearable="false"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'mini-charm'" label="active-call-id">
                                {{ active_call_id ? active_call_id : 'undefined' }}
                            </hb-form>

                            <hb-form v-if="type == 'mini-charm'" label="active-call-contact-name">
                                {{ active_call_contact_name ? active_call_contact_name : 'undefined' }}
                            </hb-form>

                            <hb-form v-if="view !== 'list' && !notification.contact_unknown" label="contact-status">
                                <HbSelect
                                    v-model="notification.contact_status"
                                    :items="contactStatusItems"
                                    placeholder="Select Contact Status"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list'" label="contact-unknown">
                                <HbSwitch
                                    v-model="notification.contact_unknown"
                                    :label="notification.contact_unknown ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list' && notification.status !== 'reservation' && type !== 'communication && reservations' && notification.selected_contact_id && notification.contact_unknown" label="Phone Number has been Added to Selected Contact">
                                <HbSwitch
                                    v-model="notification.phone_number_added_to_selected_contact"
                                    :label="notification.phone_number_added_to_selected_contact ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list' && notification.status !== 'reservation' && type !== 'communication && reservations' && notification.selected_contact_id && !notification.contact_unknown" label="Contact has been Added to Selected Contact">
                                <HbSwitch
                                    v-model="notification.contact_added_to_selected_contact"
                                    :label="notification.contact_added_to_selected_contact ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="notification.status == 'ended' && type !== 'communication'" label="hide-playback">
                                <HbSwitch
                                    v-model="hidePlayback"
                                    :label="hidePlayback ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="notification.status == 'ended' && type !== 'communication'" label="playback-url">
                                <hb-radio-group v-model="playbackRadioGroup" :disabled="hidePlayback">
                    
                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-12s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-12s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-6s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-9s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-9s.mp3"
                                    />
                                    
                                    <HbRadio
                                        label="undefined"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form v-if="view !== 'list' && (notification.status == 'voicemail' || type == 'voicemails') && type !== 'communication'" label="hide-voicemail">
                                <HbSwitch
                                    v-model="hideVoicemail"
                                    :label="hideVoicemail ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list' && (notification.status == 'voicemail' || type == 'voicemails') && type !== 'communication'" label="voicemail-url">
                                <hb-radio-group v-model="voicemailRadioGroup" :disabled="hideVoicemail">
                    
                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-12s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-12s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-6s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-9s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-9s.mp3"
                                    />
                                    
                                    <HbRadio
                                        label="undefined"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form v-if="notification.status == 'hold'" label="hold-duration-time-color">
                                <HbSelect
                                    v-model="holdDurationTimeColor"
                                    :items="[ 'caution', 'error', 'success (default)' ]"
                                    placeholder="Select Color"
                                />
                            </hb-form>

                            <hb-form v-if="view !== 'list' && (notification.status == 'missed' || notification.status == 'reservation' || notification.status == 'voicemail')" label="call-back-time-color">
                                <HbSelect
                                    v-model="callBackTimeColor"
                                    :items="[ 'caution', 'error', 'success (default)' ]"
                                    placeholder="Select Color"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'mini-charm'" label="Mini-Charm Window is in Snapped Zone">
                                <HbSwitch
                                    v-model="inSnappedZone"
                                    disabled
                                    :label="inSnappedZone ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'communication'" label="has-attachments">
                                <HbSwitch
                                    v-model="has_attachments"
                                    :label="has_attachments ? 'true' : 'false'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'communication'" label="unread">
                                <HbSwitch
                                    v-model="unread"
                                    :label="unread ? 'true' : 'false'"
                                />
                            </hb-form>

                        </hb-card>

                        <hb-card class="mt-4" no-title width="552">

                            <hb-form v-if="type !== 'communication'" label="Manually Override Title">
                                <HbTextField
                                    v-model="title"
                                    placeholder="Enter Title"
                                />
                            </hb-form>

                            <hb-form v-if="view == 'default'" label="Manually Override Communication Section Icon (must be in 'mdi-xxxxx' format)">
                                <HbTextField
                                    v-model="communication_icon"
                                    placeholder="Enter Communication Icon"
                                />
                            </hb-form>

                            <hb-form v-if="view == 'default'" label="Manually Override Communication Section Title">
                                <HbTextField
                                    v-model="communication_title"
                                    placeholder="Enter Communication Title"
                                />
                            </hb-form>

                            <hb-form v-if="view == 'default'" label="Manually Override Communication Section Time">
                                <HbTextField
                                    v-model="notification.communication_time"
                                    placeholder="Enter Communication Time"
                                    clearable
                                />
                            </hb-form>

                            <hb-form v-if="view == 'default'" label="Manually Override Communication Section Property">
                                <HbTextField
                                    v-model="notification.communication_property"
                                    placeholder="Enter Property"
                                    clearable
                                />
                            </hb-form>

                            <hb-form v-if="view == 'default'" label="Manually Override Communication Section Source">
                                <HbTextField
                                    v-model="notification.communication_source"
                                    placeholder="Enter Source"
                                    clearable
                                />
                            </hb-form>

                        </hb-card>
                    
                    </v-col>

                    <v-col cols="6" no-gutters class="mt-4">
                        <hb-card v-if="type == 'mini-charm' && incoming_notifications.length" color="#E0F5F5" title="Incoming Notifications Array">
                            <pre class="px-4" :style="incomingNotificationsArrayCodeExpanded ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="incomingNotificationsArrayCodeExpanded = !incomingNotificationsArrayCodeExpanded">
                                    {{ incomingNotificationsArrayCodeExpanded ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
{{ incoming_notifications }}
                            </pre>
                        </hb-card>

                        <hb-card :class="type == 'mini-charm' && incoming_notifications.length ? 'mt-4' : ''" color="#E0F5F5" title="Main Window Notification Object">
                            <pre class="px-4" :style="mainWindowObjectCodeExpanded ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="mainWindowObjectCodeExpanded = !mainWindowObjectCodeExpanded">
                                    {{ mainWindowObjectCodeExpanded ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
{{ notification }}
                            </pre>
                        </hb-card>
                        
                        <br /><br />
                        <span class="font-weight-medium">PLEASE DO NOT EDIT THE "HbToast.vue" or "HbToastItem.vue" FILES UNDER ANY CIRCUMSTANCES. IF YOU NEED A CHANGE OR SOMETHING FIXED, PLEASE REACH OUT TO THE DESIGN SYSTEM TEAM AND NOTIFY THEM OF YOUR REQUEST OR REACH OUT VIA "#design-system" SLACK CHANNEL.</span>
                    </v-col>

                </v-row>

                <v-slide-x-reverse-transition>
            
                    <hb-toast
                        v-if="show"
                        :loading.sync="loading"
                        :id="notification.id"
                        :type="type"
                        :view.sync="view"
                        :confirmation.sync="confirmation"
                        :active-call-id="active_call_id"
                        :active-call-contact-name="active_call_contact_name"
                        :status="notification.status"
                        :phone-number="notification.phone_number"
                        :contact-unknown="notification.contact_unknown"
                        :communication-type="notification.communication_type"
                        :communication-time="notification.communication_time"
                        :property="notification.communication_property"
                        :source="notification.communication_source"
                        :contact-id="notification.contact_id"
                        :contact-name="notification.contact_name"
                        :contact-status="notification.contact_status"
                        :contact-phone="notification.contact_phone"
                        :contact-email="notification.contact_email"
                        :contact-address="notification.contact_address"
                        :contact-access-codes="notification.contact_access_codes"
                        :contact-units="notification.contact_units"
                        :playback-url="notification.playback_url"
                        :voicemail-url="notification.voicemail_url"
                        :call-duration-time="notification.call_duration_time"
                        :hold-duration-time="notification.hold_duration_time"
                        :hold-duration-time-color="holdDurationTimeColor"
                        :call-back-time="notification.call_back_time"
                        :call-back-time-color="callBackTimeColor"
                        :selected-contact-id="notification.selected_contact_id"
                        :selected-contact-name="notification.selected_contact_name"
                        :selected-contact-status="notification.selected_contact_status"
                        :selected-contact-phone="notification.selected_contact_phone"
                        :selected-contact-email="notification.selected_contact_email"
                        :selected-contact-address="notification.selected_contact_address"
                        :selected-contact-access-codes="notification.selected_contact_access_codes"
                        :selected-contact-units="notification.selected_contact_units"
                        :reservation-id="notification.reservation_id"
                        :reservation-space-information="notification.reservation_space_information"
                        :reservation-move-in-date="notification.reservation_move_in_date"
                        :reservation-code="notification.reservation_code"
                        :reservation-in-store-price="notification.reservation_in_store_price"
                        :reservation-web-only-price="notification.reservation_web_only_price"
                        :add-as-additional-phone-number-button="!notification.phone_number_added_to_selected_contact"
                        :add-as-additional-contact-button="!notification.contact_added_to_selected_contact"
                        :switching-in-progress="notification.switching_in_progress"
                        :count="count"
                        :has-attachments="has_attachments"
                        :unread="unread"
                        :pinned="pinned"
                        :title.sync="title"
                        :communication-title.sync="communication_title"
                        :communication-icon.sync="communication_icon"
                        :hide-voicemail="hideVoicemail"
                        :hide-playback="hidePlayback"

                        @close="handleClose($event), lastEmittedEvent = '@close'"
                        @dismiss="handleDismiss($event), lastEmittedEvent = '@dismiss'"
                        @pinned-icon-clicked="pinned = !pinned, lastEmittedEvent = '@pinned-icon-clicked'"
                        @notification-clicked="'', lastEmittedEvent = '@notification-clicked'"
                        @add-as-additional-phone-number="notification.phone_number_added_to_selected_contact = true, lastEmittedEvent = '@add-as-additional-phone-number'"
                        @add-as-additional-contact="notification.contact_added_to_selected_contact = true, lastEmittedEvent = '@add-as-additional-contact'"
                        @remove-selected-contact="removeSelectedContact(), lastEmittedEvent = '@remove-selected-contact'"
                        @call-notes-button-clicked="openSuccessError($event, '@call-notes-button-clicked'), lastEmittedEvent = '@call-notes-button-clicked'"
                        @create-task-button-clicked="openSuccessError($event, '@create-task-button-clicked'), lastEmittedEvent = '@create-task-button-clicked'"
                        @profile-button-clicked="openSuccessError($event, '@profile-button-clicked'), lastEmittedEvent = '@profile-button-clicked'"
                        @email-button-clicked="openSuccessError($event, '@email-button-clicked'), lastEmittedEvent = '@email-button-clicked'"
                        @text-button-clicked="openSuccessError($event, '@text-button-clicked'), lastEmittedEvent = '@text-button-clicked'"
                        @lead-button-clicked="openSuccessError($event, '@lead-button-clicked'), lastEmittedEvent = '@lead-button-clicked'"
                        @move-in-button-clicked="openSuccessError($event, '@move-in-button-clicked'), lastEmittedEvent = '@move-in-button-clicked'"
                        @move-out-button-clicked="openSuccessError($event, '@move-out-button-clicked'), lastEmittedEvent = '@move-out-button-clicked'"
                        @payment-button-clicked="openSuccessError($event, '@payment-button-clicked'), lastEmittedEvent = '@payment-button-clicked'"
                        @transfer-button-clicked="openSuccessError($event, '@transfer-button-clicked'), lastEmittedEvent = '@transfer-button-clicked'"
                        @block-caller-button-clicked="notification.status = 'ended', lastEmittedEvent = '@block-caller-button-clicked'"
                        @send-to-voicemail-button-clicked="notification.status = 'voicemail', lastEmittedEvent = '@send-to-voicemail-button-clicked'"
                        @answer-call-button-clicked="checkIfAlreadyOnAnotherCall('answer-call-button-clicked'), lastEmittedEvent = '@answer-call-button-clicked'"
                        @put-on-hold-button-clicked="notification.status = 'hold', lastEmittedEvent = '@put-on-hold-button-clicked'"
                        @take-off-hold-button-clicked="checkIfAlreadyOnAnotherCall('take-off-hold-button-clicked'), resetHoldTimer(), lastEmittedEvent = '@take-off-hold-button-clicked'"
                        @end-call-button-clicked="endCall(), resetDurationTimer(), lastEmittedEvent = '@end-call-button-clicked'"
                        @call-back-button-clicked="type = 'mini-charm', checkIfAlreadyOnAnotherCall('call-back-button-clicked'), lastEmittedEvent = '@call-back-button-clicked'"
                        @confirmed="afterConfirmation($event), lastEmittedEvent = '@confirmed, payload = ' + $event"
                        @in-snapped-zone="inSnappedZone = $event, lastEmittedEvent = '@in-snapped-zone, payload = ' + $event"
                        @drag-stopped="lastEmittedEvent = '@drag-stopped, payload = true'"
                        @playback-played="lastEmittedEvent = '@playback-played'"
                        @playback-paused="lastEmittedEvent = '@playback-paused'"
                        @voicemail-played="lastEmittedEvent = '@voicemail-played'"
                        @voicemail-paused="lastEmittedEvent = '@voicemail-paused'"
                        
                        style="position:fixed;top:23px;right: 22px;height:min-content;max-height:100.1%;width:336px;overflow:auto;"
                    >
                        <template v-slot:toastItems>
                            
                            <hb-toast-item
                                v-for="(item, i) in incoming_notifications"
                                :key="'incoming-notification-' + i"
                                :id="item.id"
                                :status="item.status"
                                :contact-unknown="item.contact_unknown"
                                :phone-number="item.phone_number"
                                :contact-id="item.contact_id"
                                :contact-name="item.contact_name"
                                :contact-status="item.contact_status"
                                :time="item.status == 'call in' || item.status == 'call out' ? '' : item.status == 'active' ? item.call_duration_time : item.time"
                                mini-charm
                                @click="toastItemClicked(item, i), lastEmittedEvent = '@click (from HbToastItem), payload (id) = ' + $event"
                                :adding-in-progress="item.adding_in_progress"
                                :switching-in-progress="item.switching_in_progress"
                            >
                            </hb-toast-item>
                        </template>

                        <template v-slot:listItems>
                            
                            <hb-toast-item
                                v-show="type == 'missed calls' || type =='reservations' || type == 'voicemails'"
                                v-for="(item, i) in list_view_items"
                                :key="'toast-item-' + type + '-' + i"
                                :id="item.id"
                                :status="item.status"
                                :contact-unknown="item.contact_unknown"
                                :phone-number="item.phone_number"
                                :contact-id="item.contact_id"
                                :contact-name="item.contact_name"
                                :contact-status="item.contact_status"
                                :time="item.time"
                                @click="sendNotificationToDefaultView(item), lastEmittedEvent = '@click (from HbToastItem), payload (id) = ' + $event"
                            >
                            </hb-toast-item>

                        </template>

                        <template v-slot:searchContacts>
                            <!-- likely you will use server side filtering to dynamically populate the ':items="searchContactsList"' array as the user types, the 'no-filter' prop allows this -->
                            <!-- the ':search-input.sync' prop of 'searchContactsInput' dynamically updates with the user's input as they type, you can use this to send for filtering -->
                            <hb-autocomplete
                                v-model="searchContactsModel"
                                :search-input.sync="searchContactsInput"
                                :items="searchContactsList"
                                return-object
                                placeholder="Search Contacts"
                                prepend-inner-icon="mdi-magnify"
                                append-icon=""
                                background-color="white"
                                box
                                no-filter
                                @change="addSelectedContact(searchContactsModel)"
                            >
                                <template v-slot:item="{item}">
                                    <div style="max-width:272px;">

                                        <div class="pt-1 pb-4">
                                            <v-row no-gutters>
                                                <v-col cols="12" no-gutters class="hb-overflow-handler py-1 d-flex align-center">

                                                    <hb-icon small color="#101318">mdi-account</hb-icon>

                                                    <div class="font-weight-medium pl-2 hb-overflow-handler" style="margin-top:3px;">{{ item.contact_name }}</div>

                                                    <hb-status
                                                        class="pl-2"
                                                        style="position:relative;top:1.5px;"
                                                        :status="item.contact_status"
                                                    >
                                                        {{ item.contact_status }}
                                                    </hb-status>
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters v-if="item.contact_phone || item.contact_email">
                                                <v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                                                    {{ item.contact_phone }}<span v-if="item.contact_phone && item.contact_email" class="px-1">|</span> {{ item.contact_email }}
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters v-if="item.contact_address">
                                                <v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                                                    {{ item.contact_address }}
                                                </v-col>
                                            </v-row>

                                            <v-row no-gutters v-if="item.contact_units && item.contact_units.length">
                                                <v-col
                                                    cols="12"
                                                    v-for="(unit, i) in item.contact_units"
                                                    :key="item.contact_name + '_search_contact_units_' + i"
                                                    no-gutters
                                                    class="hb-font-caption hb-text-night-light d-flex align-center"
                                                >
                                                    <div style="min-height: 18px;">
                                                        <HbSpaceIcon small class="pr-1" :type="unit.type" enable-tooltip />
                                                    </div>
                                                    <div class="hb-overflow-handler">{{ unit.number ? '#' + unit.number : '' }}<span class="px-1" v-if="unit.number && unit.address">|</span>{{ unit.address }}</div>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <v-divider v-if="item !== searchContactsList[searchContactsList.length - 1]"></v-divider>
                                    </div>
                                </template>

                                <template v-slot:selection="{item}">
                                    {{ item.contact_name }}
                                </template>
                            </hb-autocomplete>
                        </template>
                    </hb-toast>
                
                </v-slide-x-reverse-transition>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre :style="toastCodeExpanded ? 'height:auto' : 'height:120px;'">
<div style="position:absolute;top:0;right:36px;" class="d-flex align-center hb-pointer" @click="toastCodeExpanded = !toastCodeExpanded">
    {{ toastCodeExpanded ? 'Collapse Code' : 'Expand Code' }}
    <hb-icon>mdi-chevron-down</hb-icon>
</div>
&lt;v-slide-x-reverse-transition>
            
    &lt;hb-toast
        v-if="show"
        :loading.sync="loading"
        :id="notification.id"
        :type="type"
        :view.sync="view"
        :confirmation.sync="confirmation"
        :active-call-id="active_call_id"
        :active-call-contact-name="active_call_contact_name"
        :status="notification.status"
        :phone-number="notification.phone_number"
        :contact-unknown="notification.contact_unknown"
        :communication-type="notification.communication_type"
        :communication-time="notification.communication_time"
        :property="notification.communication_property"
        :source="notification.communication_source"
        :contact-id="notification.contact_id"
        :contact-name="notification.contact_name"
        :contact-status="notification.contact_status"
        :contact-phone="notification.contact_phone"
        :contact-email="notification.contact_email"
        :contact-address="notification.contact_address"
        :contact-access-codes="notification.contact_access_codes"
        :contact-units="notification.contact_units"
        :playback-url="notification.playback_url"
        :voicemail-url="notification.voicemail_url"
        :call-duration-time="notification.call_duration_time"
        :hold-duration-time="notification.hold_duration_time"
        :hold-duration-time-color="holdDurationTimeColor"
        :call-back-time="notification.call_back_time"
        :call-back-time-color="callBackTimeColor"
        :selected-contact-id="notification.selected_contact_id"
        :selected-contact-name="notification.selected_contact_name"
        :selected-contact-status="notification.selected_contact_status"
        :selected-contact-phone="notification.selected_contact_phone"
        :selected-contact-email="notification.selected_contact_email"
        :selected-contact-address="notification.selected_contact_address"
        :selected-contact-access-codes="notification.selected_contact_access_codes"
        :selected-contact-units="notification.selected_contact_units"
        :reservation-id="notification.reservation_id"
        :reservation-space-information="notification.reservation_space_information"
        :reservation-move-in-date="notification.reservation_move_in_date"
        :reservation-code="notification.reservation_code"
        :reservation-in-store-price="notification.reservation_in_store_price"
        :reservation-web-only-price="notification.reservation_web_only_price"
        :add-as-additional-phone-number-button="!notification.phone_number_added_to_selected_contact"
        :add-as-additional-contact-button="!notification.contact_added_to_selected_contact"
        :switching-in-progress="notification.switching_in_progress"
        :count="count"
        :has-attachments="has_attachments"
        :unread="unread"
        :pinned="pinned"
        :title.sync="title"
        :communication-title.sync="communication_title"
        :communication-icon.sync="communication_icon"
        :hide-voicemail="hideVoicemail"
        :hide-playback="hidePlayback"

        @close="handleClose($event), lastEmittedEvent = '@close'"
        @dismiss="handleDismiss($event), lastEmittedEvent = '@dismiss'"
        @pinned-icon-clicked="pinned = !pinned, lastEmittedEvent = '@pinned-icon-clicked'"
        @notification-clicked="'', lastEmittedEvent = '@notification-clicked'"
        @add-as-additional-phone-number="notification.phone_number_added_to_selected_contact = true, lastEmittedEvent = '@add-as-additional-phone-number'"
        @add-as-additional-contact="notification.contact_added_to_selected_contact = true, lastEmittedEvent = '@add-as-additional-contact'"
        @remove-selected-contact="removeSelectedContact(), lastEmittedEvent = '@remove-selected-contact'"
        @call-notes-button-clicked="openSuccessError($event, '@call-notes-button-clicked'), lastEmittedEvent = '@call-notes-button-clicked'"
        @create-task-button-clicked="openSuccessError($event, '@create-task-button-clicked'), lastEmittedEvent = '@create-task-button-clicked'"
        @profile-button-clicked="openSuccessError($event, '@profile-button-clicked'), lastEmittedEvent = '@profile-button-clicked'"
        @email-button-clicked="openSuccessError($event, '@email-button-clicked'), lastEmittedEvent = '@email-button-clicked'"
        @text-button-clicked="openSuccessError($event, '@text-button-clicked'), lastEmittedEvent = '@text-button-clicked'"
        @lead-button-clicked="openSuccessError($event, '@lead-button-clicked'), lastEmittedEvent = '@lead-button-clicked'"
        @move-in-button-clicked="openSuccessError($event, '@move-in-button-clicked'), lastEmittedEvent = '@move-in-button-clicked'"
        @move-out-button-clicked="openSuccessError($event, '@move-out-button-clicked'), lastEmittedEvent = '@move-out-button-clicked'"
        @payment-button-clicked="openSuccessError($event, '@payment-button-clicked'), lastEmittedEvent = '@payment-button-clicked'"
        @transfer-button-clicked="openSuccessError($event, '@transfer-button-clicked'), lastEmittedEvent = '@transfer-button-clicked'"
        @block-caller-button-clicked="notification.status = 'ended', lastEmittedEvent = '@block-caller-button-clicked'"
        @send-to-voicemail-button-clicked="notification.status = 'voicemail', lastEmittedEvent = '@send-to-voicemail-button-clicked'"
        @answer-call-button-clicked="checkIfAlreadyOnAnotherCall('answer-call-button-clicked'), lastEmittedEvent = '@answer-call-button-clicked'"
        @put-on-hold-button-clicked="notification.status = 'hold', lastEmittedEvent = '@put-on-hold-button-clicked'"
        @take-off-hold-button-clicked="checkIfAlreadyOnAnotherCall('take-off-hold-button-clicked'), resetHoldTimer(), lastEmittedEvent = '@take-off-hold-button-clicked'"
        @end-call-button-clicked="endCall(), resetDurationTimer(), lastEmittedEvent = '@end-call-button-clicked'"
        @call-back-button-clicked="type = 'mini-charm', checkIfAlreadyOnAnotherCall('call-back-button-clicked'), lastEmittedEvent = '@call-back-button-clicked'"
        @confirmed="afterConfirmation($event), lastEmittedEvent = '@confirmed, payload = ' + $event"
        @in-snapped-zone="inSnappedZone = $event, lastEmittedEvent = '@in-snapped-zone, payload = ' + $event"
        @playback-played="lastEmittedEvent = '@playback-played'"
        @playback-paused="lastEmittedEvent = '@playback-paused'"
        @voicemail-played="lastEmittedEvent = '@voicemail-played'"
        @voicemail-paused="lastEmittedEvent = '@voicemail-paused'"
        
        style="position:fixed;top:23px;right: 22px;height:min-content;max-height:100.1%;width:336px;overflow:auto;"
    >
        &lt;template v-slot:toastItems>
            
            &lt;hb-toast-item
                v-for="(item, i) in incoming_notifications"
                :key="'incoming-notification-' + i"
                :id="item.id"
                :status="item.status"
                :contact-unknown="item.contact_unknown"
                :phone-number="item.phone_number"
                :contact-id="item.contact_id"
                :contact-name="item.contact_name"
                :contact-status="item.contact_status"
                :time="item.status == 'call in' || item.status == 'call out' ? '' : item.status == 'active' ? item.call_duration_time : item.time"
                mini-charm
                @click="toastItemClicked(item, i), lastEmittedEvent = '@click (from HbToastItem), payload (id) = ' + $event"
                :adding-in-progress="item.adding_in_progress"
                :switching-in-progress="item.switching_in_progress"
            >
            &lt;/hb-toast-item>
        &lt;/template>

        &lt;template v-slot:listItems>
            
            &lt;hb-toast-item
                v-show="type == 'missed calls' || type =='reservations' || type == 'voicemails'"
                v-for="(item, i) in list_view_items"
                :key="'toast-item-' + type + '-' + i"
                :id="item.id"
                :status="item.status"
                :contact-unknown="item.contact_unknown"
                :phone-number="item.phone_number"
                :contact-id="item.contact_id"
                :contact-name="item.contact_name"
                :contact-status="item.contact_status"
                :time="item.time"
                @click="sendNotificationToDefaultView(item), lastEmittedEvent = '@click (from HbToastItem), payload (id) = ' + $event"
            >
            &lt;/hb-toast-item>

        &lt;/template>

        &lt;template v-slot:searchContacts>
            &lt;!-- likely you will use server side filtering to dynamically populate the ':items="searchContactsList"' array as the user types, the 'no-filter' prop allows this -->
            &lt;!-- the ':search-input.sync' prop of 'searchContactsInput' dynamically updates with the user's input as they type, you can use this to send for filtering -->
            &lt;hb-autocomplete
                v-model="searchContactsModel"
                :search-input.sync="searchContactsInput"
                :items="searchContactsList"
                return-object
                placeholder="Search Contacts"
                prepend-inner-icon="mdi-magnify"
                append-icon=""
                background-color="white"
                box
                no-filter
                @change="addSelectedContact(searchContactsModel)"
            >
                &lt;template v-slot:item="{item}">
                    &lt;div style="max-width:272px;">

                        &lt;div class="pt-1 pb-4">
                            &lt;v-row no-gutters>
                                &lt;v-col cols="12" no-gutters class="hb-overflow-handler py-1 d-flex align-center">

                                    &lt;hb-icon small color="#101318">mdi-account&lt;/hb-icon>

                                    &lt;div class="font-weight-medium pl-2 hb-overflow-handler" style="margin-top:3px;"><span v-pre>{{ item.contact_name }}</span>&lt;/div>

                                    &lt;hb-status
                                        class="pl-2"
                                        style="position:relative;top:1.5px;"
                                        :status="item.contact_status"
                                    >
                                        <span v-pre>{{ item.contact_status }}</span>
                                    &lt;/hb-status>
                                &lt;/v-col>
                            &lt;/v-row>

                            &lt;v-row no-gutters v-if="item.contact_phone || item.contact_email">
                                &lt;v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                                    <span v-pre>{{ item.contact_phone }}</span>&lt;span v-if="item.contact_phone && item.contact_email" class="px-1">|&lt;/span> <span v-pre>{{ item.contact_email }}</span>
                                &lt;/v-col>
                            &lt;/v-row>

                            &lt;v-row no-gutters v-if="item.contact_address">
                                &lt;v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                                    <span v-pre>{{ item.contact_address }}</span>
                                &lt;/v-col>
                            &lt;/v-row>

                            &lt;v-row no-gutters v-if="item.contact_units && item.contact_units.length">
                                &lt;v-col
                                    cols="12"
                                    v-for="(unit, i) in item.contact_units"
                                    :key="item.contact_name + '_search_contact_units_' + i"
                                    no-gutters
                                    class="hb-font-caption hb-text-night-light d-flex align-center"
                                    :style="unit.type == 'storage' ? 'margin-top:-.5px;margin-bottom:-.5px;' : 'margin-top:-1.5px;'"
                                >
                                    &lt;div style="min-height: 18px;">
                                        &lt;hb-icon small class="pr-1" v-if="unit.type == 'storage'" mdi-icon="mdi-facility-custom-3" style="position:relative;top:1px;">&lt;/hb-icon>
                                        &lt;hb-icon small class="pr-1" v-else-if="unit.type == 'parking'" style="position:relative;top:1px;">mdi-truck&lt;/hb-icon>
                                        &lt;hb-icon small class="pr-1" v-else-if="unit.type == 'company'" style="position:relative;top:.5px;">mdi-domain&lt;/hb-icon>
                                        &lt;hb-icon small class="pr-1" v-else-if="unit.type == 'residential'" style="position:relative;top:.5px;">mdi-home&lt;/hb-icon>
                                    &lt;/div>
                                    &lt;div class="hb-overflow-handler" :style="unit.type !== 'storage' ? 'padding-top:2px;' : ''"><span v-pre>{{ unit.number ? '#' + unit.number : '' }}</span>&lt;span class="px-1" v-if="unit.number && unit.address">|&lt;/span><span v-pre>{{ unit.address }}</span>&lt;/div>
                                &lt;/v-col>
                            &lt;/v-row>
                        &lt;/div>

                        &lt;v-divider v-if="item !== searchContactsList[searchContactsList.length - 1]">&lt;/v-divider>
                    &lt;/div>
                &lt;/template>

                &lt;template v-slot:selection="{item}">
                    <span v-pre>{{ item.contact_name }}</span>
                &lt;/template>
            &lt;/hb-autocomplete>
        &lt;/template>
    &lt;/hb-toast>

&lt;/v-slide-x-reverse-transition>


data: {{ $data }}
</pre>
            </hb-card>

        </hb-card>

        <hb-card title="HbToast Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbToast Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbToast Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Toast Items + Code
            </template>

            <v-row class="ma-4" no-gutters>

                <v-col cols="6" no-gutters>

                    <div style="width:336px;">
                        <hb-toast-item
                            status="call in"
                            contact-name="Harry Potter"
                            contact-status="current"
                        >
                        </hb-toast-item>

                        <hb-toast-item
                            status="call out"
                            contact-unknown
                            phone-number="+1 (555) 555-5555"
                        >
                        </hb-toast-item>

                        <hb-toast-item
                            status="active"
                            contact-name="Hermione Granger"
                            contact-status="active"
                            time="26:56"
                        >
                        </hb-toast-item>

                        <hb-toast-item
                            status="ended"
                            contact-name="Ron Weasley"
                            contact-status="auction"
                            time="5m"
                        >
                        </hb-toast-item>
                    </div>
                </v-col>

                <v-col cols="6" no-gutters>

                    <div style="width:336px;">
                        <hb-toast-item
                            status="hold"
                            contact-name="Neville Longbottom"
                            contact-status="converted"
                            time="<1m"
                        >
                        </hb-toast-item>

                        <hb-toast-item
                            status="reservation"
                            contact-name="Bellatrix LeStrange"
                            contact-status="retired"
                            time="23h"
                        >
                        </hb-toast-item>

                        <hb-toast-item
                            status="missed"
                            contact-name="Ron Potter"
                            contact-status="delinquent"
                            time="1d"
                        >
                        </hb-toast-item>
                        
                        <hb-toast-item
                            status="voicemail"
                            contact-name="Harry Weasley"
                            contact-status="converted"
                            time="2d+"
                        >
                        </hb-toast-item>
                    </div>
                </v-col>

            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre :style="toastItemCodeExpanded ? 'height:auto' : 'height:120px;'">
<div style="position:absolute;top:0;right:36px;" class="d-flex align-center hb-pointer" @click="toastItemCodeExpanded = !toastItemCodeExpanded">
    {{ toastItemCodeExpanded ? 'Collapse Code' : 'Expand Code' }}
    <hb-icon>mdi-chevron-down</hb-icon>
</div>
&lt;hb-toast-item
    status="call in"
    contact-name="Harry Potter"
    contact-status="current"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="call out"
    contact-unknown
    phone-number="+1 (555) 555-5555"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="active"
    contact-name="Hermione Granger"
    contact-status="active"
    time="26:56"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="ended"
    contact-name="Ron Weasley"
    contact-status="auction"
    time="5m"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="hold"
    contact-name="Neville Longbottom"
    contact-status="converted"
    time="59:52"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="reservation"
    contact-name="Bellatrix LeStrange"
    contact-status="retired"
    time="23h"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="missed"
    contact-name="Ron Potter"
    contact-status="delinquent"
    time="2d+"
>
&lt;/hb-toast-item>

&lt;hb-toast-item
    status="voicemail"
    contact-name="Harry Weasley"
    contact-status="converted"
    time="&lt;1m"
>
&lt;/hb-toast-item>
</pre>
            </hb-card>

        </hb-card>

        <hb-card title="HbToastItem Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems2"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbToastItem Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems2"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbToastItem Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems2"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="successError"
            type="success"
            :list="successErrorList"
            :description="successErrorDescription"
            style="position:absolute;z-index:99999;"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemToasts",
        data: function() {
            return {
                show: false,
                loading: null,
                type: 'mini-charm',
                view: 'default',
                active_call_id: '',
                active_call_contact_name: '',

                notification: {
                    id: this.generateId(),
                    status: 'call in',
                    phone_number: '+1 (555) 555-5555',
                    contact_unknown: null,
                    contact_id: this.generateId(),
                    contact_name: 'Harry Potter',
                    contact_status: 'current',
                    contact_phone: '+1 (555) 555-5555',
                    contact_email: 'sampleemail@hogwarts.com',
                    contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                    contact_access_codes: [ '12345', '23456', '12321'],
                    contact_units: [
                        {
                            type: 'storage',
                            number: 'U1234',
                            address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                        },
                        {
                            type: 'parking',
                            number: 'U1235',
                            address: '3245 Bellatrix Ln., Gryffindoor, CA 99999'
                        },
                        {
                            type: 'car_wash',
                            number: 'U1236',
                            address: '32456 Bellatrix Ln., Gryffindoor, CA 99999'
                        },
                        {
                            type: 'mailbox',
                            number: 'U1237',
                            address: '324567 Bellatrix Ln., Gryffindoor, CA 99999'
                        },
                    ],
                    communication_type: 'call in',
                    communication_time: 'Today, 3:00pm',
                    communication_property: 'S0124 - Pomona - 435 Airport Way',
                    communication_source: 'Yelp | Coupon: 50%OFF',
                    call_back_time: '0:40',
                    call_duration_time: '0:00',
                    hold_duration_time: '0:00',
                    playback_url: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                    voicemail_url: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                    selected_contact_id: null,
                    selected_contact_name: null,
                    selected_contact_status: null,
                    selected_contact_phone: null,
                    selected_contact_email: null,
                    selected_contact_address: null,
                    selected_contact_access_codes: null,
                    selected_contact_units: null,
                    reservation_id: null,
                    reservation_space_information: null,
                    reservation_move_in_date: null,
                    reservation_code: null,
                    reservation_in_store_price: null,
                    reservation_web_only_price: null,
                    phone_number_added_to_selected_contact: false,
                    contact_added_to_selected_contact: false,
                    time: '<1m',
                    adding_in_progress: false,
                    switching_in_progress: false,
                },

                count: 20,
                has_attachments: true,
                unread: true,
                pinned: false,
                confirmation: '',

                incoming_notifications: [],
                list_view_items: [],
                searchContactsModel: {},
                searchContactsInput: '',
                searchContactsList: [
                    {
                        contact_id: this.generateId(),
                        contact_name: 'Ron Weasley',
                        contact_status: 'Bankruptcy',
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345', '23456', '12321'],
                        contact_units: [
                            {
                                type: 'storage',
                                number: 'U1238',
                                address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                            }
                        ],
                    },
                    {
                        contact_id: this.generateId(),
                        contact_name: 'Harry Potter',
                        contact_status: 'retired',
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345'],
                        contact_units: [
                            {
                                type: 'parking',
                                number: 'U1239',
                                address: '3245 Bellatrix Ln., Gryffindoor, CA 99999'
                            }
                        ],
                    },
                    {
                        contact_id: this.generateId(),
                        contact_name: 'Hermione Granger',
                        contact_status: 'suspended',
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345'],
                        contact_units: [
                            {
                                type: 'storage',
                                number: 'U1240',
                                address: '3245 Bellatrix Ln., Gryffindoor, CA 99999'
                            }
                        ],
                    },
                    {
                        contact_id: this.generateId(),
                        contact_name: 'Bellatrix LeStrange',
                        contact_status: 'current',
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345'],
                        contact_units: [
                            {
                                type: 'storage',
                                number: 'U1241',
                                address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                            },
                            {
                                type: 'parking',
                                number: 'U1242',
                                address: '32456 Bellatrix Ln., Gryffindoor, CA 99999'
                            },
                            {
                                type: 'car_wash',
                                number: 'U1243',
                                address: '32456 Bellatrix Ln., Gryffindoor, CA 99999'
                            },
                            {
                                type: 'locker',
                                number: 'U1244',
                                address: '324567 Bellatrix Ln., Gryffindoor, CA 99999'
                            },
                        ],
                    }
                ],
                
                /* below are manual override options, you shouldn't ever need to manually override these, but they are available if needed */
                title: 'Incoming Call...',
                communication_title: 'Call (In)',
                communication_icon: 'mdi-phone-incoming',

                /* below props are for demo only, may not be necessary for real implementation depending on how you want to build it */
                viewItems: [ 'default', 'list' ],
                typeItems: [ 'mini-charm', 'missed calls', 'reservations', 'voicemails' ],
                statusItems: [ 'active', 'call in', 'call out', 'ended', 'hold', 'missed', 'reservation', 'voicemail'],
                contactStatusItems: [ 'current', 'pending', 'delinquent', 'suspended', 'active lien', 'bankruptcy', 'auction', 'lease closed', 'converted', 'active', 'active lead', 'retired', 'retired lead' ],
                countItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 40, 60, 80, 100, 150, 200],
                holdDurationTimeColor: 'success',
                callBackTimeColor: 'success',
                communicationTypeItemsCallInCallOut: ['call in', 'call out' ],
                communicationTypeItemsMiniCharm: ['call in', 'call out', 'missed', 'reservation', 'voicemail' ],
                communicationTypeItems: ['call in', 'call out', 'missed', 'reservation', 'voicemail', 'email in', 'email out', 'text in', 'text out', 'system email', 'system text', 'document', ],
                firstNames: ['Harry', 'Hermione', 'Ron', 'Neville', 'Bellatrix', 'Newt', 'Draco'],
                lastNames: ['Potter', 'Longbottom', 'Granger', 'Weasley', 'LeStrange', 'Scamander', 'Malfoy'],
                notificationStatuses: [ 'current', 'pending', 'delinquent', 'suspended', 'active lien', 'bankruptcy', 'auction', 'lease closed', 'converted', 'active', 'retired' ],
                randomTimes: ['<1m', '5m', '23m', '59m', '1h', '6h', '15h', '23h', '1d', '2d', '2d+'],
                randomDateTimes: ['Today, 3:30pm', 'Jun 3, 2023, 5:00pm', 'Dec 25, 2023, 12:00pm', 'Mar 23, 2023, 7:00am', 'Aug 4, 2023, 3:00pm'],
                durationTimeMinutes: 0,
                durationTime: 0,
                durationInterval: null,
                holdTimeMinutes: 0,
                holdTime: 0,
                holdInterval: null,
                addingIncomingNotificationInProgress: false,
                toastCodeExpanded: false,
                toastItemCodeExpanded: false,
                incomingNotificationsArrayCodeExpanded: false,
                mainWindowObjectCodeExpanded: false,
                successError: false,
                successErrorDescription: '',
                successErrorList: [],
                inSnappedZone: true,
                lastEmittedEvent: '',
                playbackRadioGroup: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                voicemailRadioGroup: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                hideVoicemail: false,
                hidePlayback: false,

                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Has Payload', value: 'payload' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'loading', type: 'boolean or string', default: 'null', description: 'If set to true, this will show an infinite loading progress animation above the mini charm footer. Clicking on one of the default actions will set this prop to the name of the button pressed, will show the loading animation, and will apply a focus outline around the clicked action. You must sync this prop like :loading.sync="loading" in order for this to work properly. You must always be sure to set this prop to false after every action is completed or if you are switching between calls or any other appropriate scenario, otherwise the animation will keep running.' },
                    { name: 'id', type: 'string', default: 'undefined', description: 'Pass the id of the notification into this prop.' },
                    { name: 'type', type: 'string', default: 'undefined', description: 'This prop controls how the notification looks and what will and will not be shown. Set this prop to "mini-charm" when using mini-charm. Set to "missed calls", "reservations", or "voicemails" for the correlating functionality.' },
                    { name: 'view', type: 'string', default: 'default', description: 'Set this prop to "list" for "missed calls", "reservations", or "voicemails" types for the list view. Set it to default after a list item is clicked to move into default view - "mini-charm" type only uses "default" view.' },
                    { name: 'active-call-id', type: 'string', default: 'undefined', description: 'Set the active incoming or outgoing call to this prop so that only one active call is allowed at a time. This works in conjunction with "confirmation.sync" prop which must be used with the ".sync" modifier in order to function correctly. This will show a confirmation message if the user tries to switch to a new call when there is already one active. When switching, be sure to replace the old active call id with the new active call id into this "active-call-id" prop.' },
                    { name: 'active-call-contact-name', type: 'string', default: 'undefined', description: 'Same as above prop, except pass the contact name into this prop. This is used so that the confirmation message shows the name of the current active call. When switching to another call, be sure to always replace the old active call name with the new active call name.' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'This prop controls the visual styling of the border and header and sets the appropriate title text/actions. This is for the status of the call/notification and you will be using/changing this prop a lot. The available options are "active", "call in", "call out", "ended", "hold", "missed", "reservation", and "voicemail".' },
                    { name: 'phone-number', type: 'string', default: 'undefined', description: 'Set the phone number of the incoming call/notification here.' },
                    { name: 'contact-unknown', type: 'boolean', default: 'false', description: 'If the call/notification is from an unknown contact, set this prop to true. This is necessary for the communication area to display the right call/notification details and correlating actions.' },
                    { name: 'contact-id', type: 'string', default: 'undefined', description: 'If the incoming call/notification is from a known contact, place the contact id here.' },
                    { name: 'contact-name', type: 'string', default: 'undefined', description: 'Same as above, place the contact name here.' },
                    { name: 'contact-status', type: 'string', default: 'undefined', description: 'Same as above, place the contact status here.' },
                    { name: 'contact-phone', type: 'string', default: 'undefined', description: 'Same as above, place the contact phone number here.' },
                    { name: 'contact-email', type: 'string', default: 'undefined', description: 'Same as above, place the contact email here.' },
                    { name: 'contact-address', type: 'string', default: 'undefined', description: 'Same as above, place the contact address here.' },
                    { name: 'contact-access-codes', type: 'array', default: '', description: 'Same as above, place the contact access codes here. Must be in an array like: [ "12345", "23456", etc... ]' },
                    { name: 'contact-units', type: 'array', default: '', description: 'Same as above, place the contact units here. Must be an array of objects. Please see the expanded code elsewhere on this page for how to structure the object keys.' },
                    { name: 'communication-type', type: 'string', default: 'undefined', description: 'Set this prop appropriately to display the proper text and icon in the first section of the notification which is called the communication section. The available options are "call in", "call out", "missed", "reservation", "voicemail", "email in", "email out", "text in", "text out", "system email", "system text", and "document". You likely will have to change this prop as the "status" of the call changes. Or sometimes you will not. Please consult with PMs or designers if you have questions on where/when things will change and when it is appropriate to save that entry into the database or whether to create a new notification id/entry.' },
                    { name: 'communication-time', type: 'string', default: 'undefined', description: 'Set this prop to the initial communication time that the call/notification came in.' },
                    { name: 'property', type: 'string', default: 'undefined', description: 'If the property that the caller or notification is trying to reach is known, set it here.' },
                    { name: 'source', type: 'string', default: 'undefined', description: 'If the source of the caller or notification is known, set it here.' },
                    { name: 'call-back-time-color', type: 'string', default: 'undefined', description: 'This controls the call back time color. Available options are "success" which is the default or "caution" or "error". These colors will change as more time is elapsed to show urgency to the employee that they need to call this person back soon.' },
                    { name: 'call-back-time', type: 'string', default: '0:00', description: 'Set this to the call back time value. This is the time since the call/notification first came in or ended.' },
                    { name: 'call-duration-time', type: 'string', default: '0:00', description: 'Set this as a running timer when the call starts and stop the timer when the call ends.' },
                    { name: 'hold-duration-time-color', type: 'string', default: 'undefined', description: 'This controls the hold duration time color. Available options are "success" which is the default or "caution" or "error". These colors will change as more time is elapsed to show urgency to the employee that they need to take this person off hold soon.' },
                    { name: 'hold-duration-time', type: 'string', default: '0:00', description: 'Set this as a running timer when the hold starts and stop & reset the timer when the hold ends.' },
                    { name: 'playback-url', type: 'string', default: 'undefined', description: 'Set the call playback url here if there is one and the call playback audio controls will show up. If there is no call playback, set this to empty/undefined to hide the audio controls.' },
                    { name: 'voicemail-url', type: 'string', default: 'undefined', description: 'Set the voicemail url here if there is one and the voicemail audio controls will show up. If there is no voicemail, set this to empty/undefined to hide the audio controls.' },
                    { name: 'hide-playback', type: 'boolean', default: 'false', description: 'Set to true to hide the playback call/buttons/timer. You should not ever need to do this as the functionality shows/hides based upon the "playback-url" prop, but it is available if needed.' },
                    { name: 'hide-voicemail', type: 'boolean', default: 'false', description: 'Set to true to hide the voicemail message/buttons/timer. You should not ever need to do this as the functionality shows/hides based upon the "voicemail-url" prop, but it is available if needed.' },
                    { name: 'selected-contact-id', type: 'string', default: 'undefined', description: 'If the user has selected to link a different contact than the initial caller/notification, place the contact id for that here. This selected contact id should take priority over the intial contact when clicking buttons such as "Profile", so when clicking that button, it will go to the profile of the selected-contact-id rather than the profile of the contact-id. You must add custom logic to handle these situations.' },
                    { name: 'selected-contact-name', type: 'string', default: 'undefined', description: 'Same as above, place the selected contact name here.' },
                    { name: 'selected-contact-status', type: 'string', default: 'undefined', description: 'Same as above, place the selected contact status here.' },
                    { name: 'selected-contact-phone', type: 'string', default: 'undefined', description: 'Same as above, place the selected contact phone number here.' },
                    { name: 'selected-contact-email', type: 'string', default: 'undefined', description: 'Same as above, place the selected contact email here.' },
                    { name: 'selected-contact-address', type: 'string', default: 'undefined', description: 'Same as above, place the selected contact address here.' },
                    { name: 'selected-contact-access-codes', type: 'array', default: '', description: 'Same as above, place the selected contact access codes here. Must be in an array like: [ "12345", "23456", etc... ]' },
                    { name: 'selected-contact-units', type: 'array', default: '', description: 'Same as above, place the selected contact units here. Must be an array of objects. Please see the expanded code elsewhere on this page for how to structure the object keys.' },
                    { name: 'reservation-id', type: 'string', default: 'undefined', description: 'Set the reservation id here if there is one.' },
                    { name: 'reservation-space-information', type: 'string', default: 'undefined', description: 'Set the reservation space information here if there is one.' },
                    { name: 'reservation-move-in-date', type: 'string', default: 'undefined', description: 'Set the reservation move-in date here if there is one.' },
                    { name: 'reservation-code', type: 'string', default: 'undefined', description: 'Set the reservation code here if there is one' },
                    { name: 'reservation-in-store-price', type: 'string', default: 'undefined', description: 'Set the reservation in store price here if there is one.' },
                    { name: 'reservation-web-only-price', type: 'string', default: 'undefined', description: 'Set the reservation web only price here if there is one.' },
                    { name: 'switching-in-progress', type: 'boolean', default: 'false', description: 'When switching between the main notification and an incoming call/notification, you must set logic so that the animations run correctly. Set this to true when the switching first occurs and set it to false after the switching FULLY completes. Please see expanded code elsewhere on this page for examples on how to do this.' },
                    { name: 'vue-draggable-resizable', type: 'boolean', default: 'true', description: 'If you want to turn off the draggable functionality, set this to false. Dragging functionality should only be used for the type "mini-charm" variant.' },
                    { name: 'confirmation', type: 'string', default: 'undefined', description: 'This prop is used to throw a confirmation message when a user is already on an active call and they try to answer another one, make an outgoing call, or take a different person off hold. This prop must be synced from the parent to the component (by using "confirmation.sync") to function correctly. Additional methods must be used to check if there is already an active call. Please see expanded code elsewhere for examples on how to do this.' },
                    { name: 'close-button', type: 'boolean', default: 'true', description: 'Set this prop to false if for some reason you want to turn off the close button. You should not need to change this prop.' },
                    { name: 'dismiss-button', type: 'boolean', default: 'true', description: 'Set this prop to false if for some reason you want to turn off the dismiss button. You should not need to change this prop.' },
                    { name: 'search-contacts-button', type: 'boolean', default: 'true', description: 'Set this prop to false if for some reason you want to turn off the search for a selected contacts button/icon. You should not need to change this prop.' },
                    { name: 'add-as-additional-phone-number-button', type: 'boolean', default: 'true', description: 'Set this prop to false after adding an additional phone number icon has been clicked and successfully saved to the contact. This will turn off the button because the action has already been done and we do not want to allow the user to do it again. However if selected contacts have changed or something else has occurred, you may have to manually reenable this again. Please contact a PM or designer for scenarios on when/what to do.' },
                    { name: 'add-as-additional-contact-button', type: 'boolean', default: 'true', description: 'Set this prop to false after adding an additonal contact icon has been clicked and successfully saved to the contact. This will turn off the button because the action has already been done and we do not want to allow the user to do it again. However if selected contacts have changed or something else has occurred, you may have to manually reenable this again. Please contact a PM or designer for scenarios on when/where/what to do.' },
                    { name: 'lead-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Lead button. You likely will never have to do this manually.' },
                    { name: 'move-in-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Move-In button. You likely will never have to do this manually.' },
                    { name: 'move-out-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Move-Out button. You likely will never have to do this manually.' },
                    { name: 'payment-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Payment button. You likely will never have to do this manually.' },
                    { name: 'profile-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Profile button. You likely will never have to do this manually.' },
                    { name: 'transfer-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Transfer button. You likely will never have to do this manually.' },
                    { name: 'call-notes-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Call Notes button. You likely will never have to do this manually.' },
                    { name: 'create-task-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Create Task button. You likely will never have to do this manually.' },
                    { name: 'email-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Email button. You likely will never have to do this manually.' },
                    { name: 'text-button-disabled', type: 'boolean', default: 'false', description: 'Set this to true to disable the Text button. You likely will never have to do this manually.' },
                    { name: 'block-caller-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Block Caller Button. Block caller functionality is not fully mapped out or known yet. In the meantime, you can just set this prop to false until design figures out the functionality.' },
                    { name: 'send-to-voicemail-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Send to Voicemail Button. You likely will never have to do this manually.' },
                    { name: 'answer-call-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Answer Call Button. You likely will never have to do this manually.' },
                    { name: 'put-on-hold-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Put on Hold Button. You likely will never have to do this manually.' },
                    { name: 'take-off-hold-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Take Off Hold Button. You likely will never have to do this manually.' },
                    { name: 'end-call-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the End Call Button. You likely will never have to do this manually.' },
                    { name: 'call-back-button', type: 'boolean', default: 'true', description: 'Set this to false to turn off the Call Back Button. You likely will never have to do this manually.' },
                    { name: 'box-shadow', type: 'boolean', default: 'true', description: 'Set this to false to turn off the box shadow. You likely will never have to do this manually.' },
                    { name: 'selected', type: 'boolean', default: 'false', description: 'Set this to true to make the selected item have a thicker teal outline. You will likely only ever use this for communication center to indicate which one is the current selection.' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Override the title text. You should not ever need to do this.' },
                    { name: 'count', type: 'number', default: '0', description: 'Set the count of the number to show in the orange bubble for "missed calls", "reservations", and "voicemails" types. (i.e. the length of the array of items)' },
                    { name: 'communication-icon', type: 'string', default: 'undefined', description: 'Override the communication icon ("mdi-xxxx" format). You should not ever need to do this.' },
                    { name: 'communication-icon-color', type: 'string', default: 'undefined', description: 'Override the communication icon color. You should not ever need to do this.' },
                    { name: 'communication-title', type: 'string', default: 'undefined', description: 'Override the communication title text. You should not ever need to do this.' },
                    { name: 'communication-title-color', type: 'string', default: 'undefined', description: 'Override the communication title text color. You should not ever need to do this.' }
                ],
                slotItems: [
                    { name: 'toastItems', description: 'This slot is where you would place an array of additional incoming notifications.' },
                    { name: 'listItems', description: 'This slot is where you would place an array of notifications for the "list" view of "missed calls", "reservations", and "voicemails" types.' },
                    { name: 'searchContacts', description: 'This slot is where you would place your search input for linking/selecting a different contact than the original caller/notification contact. You can and should probably use the HbSelect code provided for this slot in the demo code at the beginning of this page as your starting point.' },
                    { name: 'footerLeftActions', description: 'This slot is where you can override the default left actions associated with each status type. You should not need to do this.' },
                    { name: 'callDurationTime', description: 'This slot is where you can set the call duration time/timer. You can also do this with a prop. But this slot is available if you need more flexibility.' },
                    { name: 'playbackTime', description: 'This slot is where you can override the default call playback time/timer. You should not need to use this, but this slot is available if needed.' },
                    { name: 'voicemailTime', description: 'This slot is where you can override the default voicemail time/timer. You should not need to use this, but this slot is available if needed.' },
                    { name: 'footerRightActions', description: 'This slot is where you can override the default right actions associated with each status type. You should not need to do this.' },
                    { name: 'callBackTime', description: 'This slot is where you can set the call back time/timer. You can also do this with a prop. But this slot is available if you need more flexibility.' },
                    { name: 'holdDurationTime', description: 'This slot is where you can set hold duration time/timer. You can also do this with a prop. But this slot is available if you need more flexibility.' },
                    { name: 'title', description: 'This slot is where you can manually set the content of the title header. You should not need to do this.' },
                ],
                eventItems: [
                    { name: '@click', payload: 'Yes', description: 'Event emitted whenever anywhere in HbToast is clicked.' },
                    { name: '@confirmed', payload: 'Yes', description: 'Event emitted when user confirms call switch. Payload is provided which contains the correct string to pass into the "status" prop to show the correct border/header/actions.' },
                    { name: '@close', payload: 'No', description: 'Event emitted when close icon or close button is clicked.' },
                    { name: '@dismiss', payload: 'Yes', description: 'Event emitted when dismiss button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@add-as-additional-phone-number', payload: 'No', description: 'Event emitted when add as additional phone number icon is clicked.' },
                    { name: '@add-as-additional-contact', payload: 'No', description: 'Event emitted when add as additional contact icon is clicked.' },
                    { name: '@remove-selected-contact', payload: 'No', description: 'Event emitted when remove selected contact icon is clicked.' },
                    { name: '@call-notes-button-clicked', payload: 'Yes', description: 'Event emitted when call notes button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@create-task-button-clicked', payload: 'Yes', description: 'Event emitted when create task button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@profile-button-clicked', payload: 'Yes', description: 'Event emitted when profile button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@email-button-clicked', payload: 'Yes', description: 'Event emitted when email button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@text-button-clicked', payload: 'Yes', description: 'Event emitted when text button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@lead-button-clicked', payload: 'Yes', description: 'Event emitted when lead button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@move-in-button-clicked', payload: 'Yes', description: 'Event emitted when move-in button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@move-out-button-clicked', payload: 'Yes', description: 'Event emitted when move-out button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@payment-button-clicked', payload: 'Yes', description: 'Event emitted when payment button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@profile-button-clicked', payload: 'Yes', description: 'Event emitted when profile button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@transfer-button-clicked', payload: 'Yes', description: 'Event emitted when transfer button is clicked. Payload is provided which contains helper data to pass into the next action.' },
                    { name: '@block-caller-button-clicked', payload: 'No', description: 'Event emitted when block caller button is clicked. Block caller functionality is TBD. This may turn into a menu dropdown.' },
                    { name: '@call-back-button-clicked', payload: 'No', description: 'Event emitted when call back button is clicked.' },
                    { name: '@put-on-hold-button-clicked', payload: 'No', description: 'Event emitted when put on hold button is clicked.' },
                    { name: '@end-call-button-clicked', payload: 'No', description: 'Event emitted when end call button is clicked.' },
                    { name: '@send-to-voicemail-button-clicked', payload: 'No', description: 'Event emitted when send to voicemail button is clicked.' },
                    { name: '@answer-call-button-clicked', payload: 'No', description: 'Event emitted when answer call button is clicked.' },
                    { name: '@take-off-hold-button-clicked', payload: 'No', description: 'Event emitted when take off hold button is clicked.' },
                    { name: '@in-snapped-zone', payload: 'Yes', description: 'Event emitted when the toast is in or out of the snapped zone area. Payload will be either "true" or "false".' },
                    { name: '@drag-stopped', payload: 'Yes', description: 'Event emitted when the toast drag has stopped except for when toast snaps into snap zone area. Payload will be "true".' },
                    { name: '@playback-played', payload: 'No', description: 'Event emitted when the call playback is played or unpaused.' },
                    { name: '@playback-paused', payload: 'No', description: 'Event emitted when the call playback is paused.' },
                    { name: '@voicemail-played', payload: 'No', description: 'Event emitted when the voicemail is played or unpaused.' },
                    { name: '@voicemail-paused', payload: 'No', description: 'Event emitted when the voicemail is paused.' }
                ],

                propItems2: [
                    { name: 'id', type: 'string', default: 'undefined', description: 'Pass the id of the notification into this prop.' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'Pass the status of the notification into this prop.' },
                    { name: 'contact-unknown', type: 'boolean', default: 'false', description: 'If contact is unknown, set this to true so that the display/styling can adjust.' },
                    { name: 'phone-number', type: 'string', default: 'undefined', description: 'Pass the phone number of the caller/notification into this prop.' },
                    { name: 'contact-id', type: 'string', default: 'undefined', description: 'If the call/notification is from a known contact, place the contact id here.' },
                    { name: 'contact-name', type: 'string', default: 'undefined', description: 'Same as above, place the contact name here.' },
                    { name: 'contact-status', type: 'string', default: 'undefined', description: 'Same as above, place the contact status here.' },
                    { name: 'time', type: 'string', default: 'undefined', description: 'Pass the time you want to display into this prop. Possible formats of the time include: "<1m", "1m", "59m", "1hr", "23hr", "1d", "2d", "2d+". Incoming and outgoing calls should not show a time. Also, active calls should have a "00:00" format running timer instead of the usual format. Please contact a PM or designer if you need clarification on what type of formatting is needed in different scenarios.' },
                    { name: 'mini-charm', type: 'boolean', default: 'false', description: 'Set this to true for each item when using the "toastItems" slot for HbToast. This applies the correct box-shadow styling between each entry. This is necessary to display properly.' },
                    { name: 'adding-in-progress', type: 'boolean', default: 'false', description: 'Set this to true for each incoming notification that is added to the incoming notification array. This is necessary for the animation to run properly. After the animation has fully run, set this to false for the correlating array item/object.' },
                    { name: 'switching-in-progress', type: 'boolean', default: 'false', description: 'Set this to true for the incoming notification array item/object that will be switching into main view. This is necessary for the animation to run properly. After the animation has fully run, set this to false for the corresponding item (which should now be out of the incoming notifications array and should now be the main toast notification).' },
                    { name: 'icon', type: 'string', default: 'undefined', description: 'Override the toast item icon. You should not need to do this.' },
                    { name: 'pointer', type: 'boolean', default: 'true', description: 'Set this to false to turn off the pointer cursor.' }
                ],
                slotItems2: [
                    { name: 'timeSlot', description: 'This slot is where you can set the time slot text or timer. You can also do this with a prop. But this slot is available if you need more flexibility.' }
                ],
                eventItems2: [
                    { name: '@click', payload: 'Yes', description: 'Event emitted when user clicks on an HbToastItem. Payload provided is the notification id.' },
                ],
            };
        },
        created(){
            setTimeout(() => {
                this.show = true;
            }, 1500);
            this.generateListViewItems('missed');

            setTimeout(() => {
                if(this.show && this.type !== 'missed calls' && this.type !== 'reservations' && this.type !== 'voicemails'){
                    this.addIncomingNotification('reservation');
                }
            }, 8000);
        },
        methods: {
            toastItemClicked(notification, i){
                /* need to reset confirmation prop to make sure it's not showing on the notification that swaps in */
                this.confirmation = '';

                /* setting "switching_in_progress" and additional logic properly is important to make sure animations run completely and race conditions don't occur */
                /* switching animation takes .35 seconds to complete  */
                this.notification.switching_in_progress = true;
                notification.switching_in_progress = true;
                this.$set(this.incoming_notifications, i, notification);

                setTimeout(() => {
                    this.incoming_notifications[i].switching_in_progress = false;
                    
                    let currentNotification = this.notification;

                    this.incoming_notifications.splice(i, 1);
                    this.incoming_notifications.push(currentNotification);
                    this.notification = notification;

                    this.resetDurationTimer();
                    this.resetHoldTimer();
                    
                    this.incoming_notifications[this.incoming_notifications.length - 1].switching_in_progress = false;
                }, 350);

                this.searchContactsModel = {};
                this.searchContactsInput = '';
            },
            sendNotificationToDefaultView(item){
                this.notification = item;
                this.view = 'default';

                this.searchContactsModel = {};
                this.searchContactsInput = '';
            },
            addIncomingNotification(status){
                /* 
                    1. if multiple notifications come in at same time or soon after each other, be sure to code logic to allow the first one to complete rendering/loading/animating before allowing the next one to come in and so on
                    2. animation takes .2 seconds so you must wait for that to complete before adding the next one to the array 
                    3. changing "this.addingIncomingNotificationInProgress" to true here then to false after .2 seconds here is just a crude implementation which disables the add new notification playground buttons until the current click functionality completes, likely need a different solution for the actual implementation
                */
                this.addingIncomingNotificationInProgress = true;

                var newCall = {
                    id: this.generateId(),
                    status: status,
                    phone_number: '+1 (555) 555-5555',
                    contact_unknown: null,
                    contact_id: this.generateId(),
                    contact_name: this.generateName(),
                    contact_status: this.generateStatus(),
                    contact_phone: '+1 (555) 555-5555',
                    contact_email: 'sampleemail@hogwarts.com',
                    contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                    contact_access_codes: [ '12345' ],
                    contact_units: [
                        {
                            type: 'storage',
                            number: 'U1234',
                            address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                        }
                    ],
                    communication_type: status == 'call in' ? 'call in' : status == 'missed' ? 'missed' : status == 'reservation' ? 'reservation' : status == 'voicemail' ? 'voicemail' : this.generateCommunicationTypeCallInCallOut(),
                    communication_time: this.generateDateTime(),
                    communication_property: 'S0124 - Pomona - 435 Airport Way',
                    communication_source: 'Yelp | Coupon: 50%OFF',
                    call_back_time: this.generateTime(),
                    call_duration_time: this.generateTime(),
                    hold_duration_time: this.generateTime(),
                    playback_url: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                    voicemail_url: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                    selected_contact_id: null,
                    selected_contact_name: null,
                    selected_contact_status: null,
                    selected_contact_phone: null,
                    selected_contact_email: null,
                    selected_contact_address: null,
                    selected_contact_access_codes: null,
                    selected_contact_units: null,
                    reservation_id: status == 'reservation' ? this.generateId() : null,
                    reservation_space_information: status == 'reservation' ? "5' x 5', Climate Controlled" : null,
                    reservation_move_in_date: status == 'reservation' ? 'Jun 24, 2022' : null,
                    reservation_code: status == 'reservation' ? 'WL9884765' : null,
                    reservation_in_store_price: status == 'reservation' ? '$ 160.00' : null,
                    reservation_web_only_price: status == 'reservation' ? '$ 148.00' : null,
                    phone_number_added_to_selected_contact: false,
                    contact_added_to_selected_contact: false,
                    time: this.generateTimeToastItem(),
                    adding_in_progress: true,
                    switching_in_progress: false,
                }
                
                this.incoming_notifications.push(newCall);

                if(status == 'active'){
                    this.active_call_id = newCall.id;
                    this.active_call_contact_name = newCall.contact_name;
                }

                setTimeout(() => {
                    this.incoming_notifications[this.incoming_notifications.length - 1].adding_in_progress = false;
                    this.addingIncomingNotificationInProgress = false;
                }, 200);
            },
            generateId() {
                return Math.random().toString(16).slice(2);
            },
            generateName() {
                return this.firstNames[Math.floor(Math.random()*this.firstNames.length)] + ' ' + this.lastNames[Math.floor(Math.random()*this.lastNames.length)];
            },
            generateStatus() {
                return this.notificationStatuses[Math.floor(Math.random()*this.notificationStatuses.length)];
            },
            generateCommunicationType() {
                return this.communicationTypeItemsMiniCharm[Math.floor(Math.random()*this.communicationTypeItemsMiniCharm.length)];
            },
            generateCommunicationTypeCallInCallOut() {
                return this.communicationTypeItemsCallInCallOut[Math.floor(Math.random()*this.communicationTypeItemsCallInCallOut.length)];
            },
            pad(number) {
                return ((number<10)?"0":"")+number.toString();
            },
            generateDateTime() {
                return this.randomDateTimes[Math.floor(Math.random()*this.randomDateTimes.length)];
            },
            generateTime() {
                var MM = Math.floor(Math.random() * 59) + 1;
                var ss = this.pad(Math.floor(Math.random() * 59) + 1);

                return MM + ':' + ss;
            },
            generateTimeToastItem() {
               return this.randomTimes[Math.floor(Math.random()*this.randomTimes.length)];
            },
            startDurationTimer() {
                this.durationInterval = setInterval(this.incrementDurationTime, 1000);
            },
            incrementDurationTime() {
                if(this.durationTime == 59){
                    this.durationTime = 0;
                    this.durationTimeMinutes = parseInt(this.durationTimeMinutes) + 1;
                } else {
                    this.durationTime = parseInt(this.durationTime) + 1;
                }
                this.notification.call_duration_time = this.durationTimeMinutes + ':' + this.pad(this.durationTime);
            },
            resetDurationTimer() {
                clearInterval(this.durationInterval);
                this.durationTime = 0;
                this.durationTimeMinutes = 0;
                this.notification.call_duration_time = '0:00';
            },
            startHoldTimer() {
                this.holdInterval = setInterval(this.incrementHoldTime, 1000);
            },
            incrementHoldTime() {
                if(this.holdTime == 59){
                    this.holdTime = 0;
                    this.holdTimeMinutes = parseInt(this.holdTimeMinutes) + 1;
                } else {
                    this.holdTime = parseInt(this.holdTime) + 1;
                }
                this.notification.hold_duration_time = this.holdTimeMinutes + ':' + this.pad(this.holdTime);
            },
            resetHoldTimer() {
                clearInterval(this.holdInterval);
                this.holdTime = 0;
                this.holdTimeMinutes = 0;
                this.notification.hold_duration_time = '0:00';
            },
            generateListViewItems(status){
                this.list_view_items = [];
                for (let i = 0; i < this.count; i++) {
                    var data = {
                        id: this.generateId(),
                        status: status,
                        phone_number: '+1 (555) 555-5555',
                        contact_unknown: null,
                        contact_id: this.generateId(),
                        contact_name: this.generateName(),
                        contact_status: this.generateStatus(),
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345' ],
                        contact_units: [
                            {
                                type: 'storage',
                                number: 'U1234',
                                address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                            }
                        ],
                        communication_type: status,
                        communication_time: this.generateDateTime(),
                        communication_property: 'S0124 - Pomona - 435 Airport Way',
                        communication_source: 'Yelp | Coupon: 50%OFF',
                        call_back_time: this.generateTime(),
                        call_duration_time: this.generateTime(),
                        hold_duration_time: this.generateTime(),
                        playback_url: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                        voicemail_url: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                        selected_contact_id: null,
                        selected_contact_name: null,
                        selected_contact_status: null,
                        selected_contact_phone: null,
                        selected_contact_email: null,
                        selected_contact_address: null,
                        selected_contact_access_codes: null,
                        selected_contact_units: null,
                        reservation_id: null,
                        reservation_space_information: null,
                        reservation_move_in_date: null,
                        reservation_code: null,
                        reservation_in_store_price: null,
                        reservation_web_only_price: null,
                        phone_number_added_to_selected_contact: false,
                        contact_added_to_selected_contact: false,
                        time: this.generateTimeToastItem(),
                        adding_in_progress: false,
                        switching_in_progress: false,
                    }
                    this.list_view_items.push(data);
                };
            },
            addSelectedContact(item){

                if(item && item.contact_id){
                    this.notification.selected_contact_id = item.contact_id;
                    this.notification.selected_contact_name = item.contact_name;
                    this.notification.selected_contact_status = item.contact_status;
                    this.notification.selected_contact_phone = item.contact_phone;
                    this.notification.selected_contact_email = item.contact_email;
                    this.notification.selected_contact_address = item.contact_address;
                    this.notification.selected_contact_access_codes = item.contact_access_codes;
                    this.notification.selected_contact_units = item.contact_units;
                }

                this.searchContactsModel = {};
                this.searchContactsInput = '';
            },
            removeSelectedContact(item){
                this.notification.selected_contact_id = null;
                this.notification.selected_contact_name = null;
                this.notification.selected_contact_status = null;
                this.notification.selected_contact_phone = null;
                this.notification.selected_contact_email = null;
                this.notification.selected_contact_address = null;
                this.notification.selected_contact_access_codes = null;
                this.notification.selected_contact_units = null;
            },
            openSuccessError(payload, buttonClicked){
                this.successErrorDescription = 'Button was clicked:'
                this.successErrorList = [
                    'Event: ' + buttonClicked,
                    'Payload: ' + JSON.stringify(payload, null, 2)
                ]
                this.successError = true;
            },
            handleDismiss(payload){
                if(this.incoming_notifications.length){
                    this.notification = this.incoming_notifications[this.incoming_notifications.length -1];
                    this.incoming_notifications.pop();
                } else {
                    this.show = false;
                    this.notification = {
                        id: this.generateId(),
                        status: 'call in',
                        phone_number: '+1 (555) 555-5555',
                        contact_unknown: null,
                        contact_id: this.generateId(),
                        contact_name: this.generateName(),
                        contact_status: this.generateStatus(),
                        contact_phone: '+1 (555) 555-5555',
                        contact_email: 'sampleemail@hogwarts.com',
                        contact_address: '12345 Hogwarts Castle, Gryffindoor, CA 99999',
                        contact_access_codes: [ '12345' ],
                        contact_units: [
                            {
                                type: 'storage',
                                number: 'U1234',
                                address: '324 Bellatrix Ln., Gryffindoor, CA 99999'
                            }
                        ],
                        communication_type: status == 'call in' ? 'call in' : status == 'missed' ? 'missed' : status == 'reservation' ? 'reservation' : status == 'voicemail' ? 'voicemail' : this.generateCommunicationTypeCallInCallOut(),
                        communication_time: this.generateDateTime(),
                        communication_property: 'S0124 - Pomona - 435 Airport Way',
                        communication_source: 'Yelp | Coupon: 50%OFF',
                        call_back_time: this.generateTime(),
                        call_duration_time: this.generateTime(),
                        hold_duration_time: this.generateTime(),
                        playback_url: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                        voicemail_url: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                        selected_contact_id: null,
                        selected_contact_name: null,
                        selected_contact_status: null,
                        selected_contact_phone: null,
                        selected_contact_email: null,
                        selected_contact_address: null,
                        selected_contact_access_codes: null,
                        selected_contact_units: null,
                        reservation_id: null,
                        reservation_space_information: null,
                        reservation_move_in_date: null,
                        reservation_code: null,
                        reservation_in_store_price: null,
                        reservation_web_only_price: null,
                        phone_number_added_to_selected_contact: false,
                        contact_added_to_selected_contact: false,
                        time: this.generateTimeToastItem(),
                        adding_in_progress: false,
                        switching_in_progress: false,
                    };
                }
                this.openSuccessError(payload, '@dismiss');
            },
            handleClose(){
                if(this.type == 'mini-charm' && this.incoming_notifications.length){
                    this.notification = this.incoming_notifications[this.incoming_notifications.length -1];
                    this.incoming_notifications.pop();
                    this.openSuccessError(null, '@close');
                } else {
                    this.show = false;
                    this.openSuccessError(null, '@close');
                }
            },
            checkIfAlreadyOnAnotherCall(event){
                if(this.incoming_notifications.length && this.incoming_notifications.some(o => o.status == 'active' || o.status == 'call out')) {
                    if(event == 'answer-call-button-clicked'){
                        this.confirmation = 'answer';
                    } else if(event == 'take-off-hold-button-clicked'){
                        this.confirmation = 'take off hold';
                    } else if(event == 'call-back-button-clicked'){
                        this.confirmation = 'call back';
                    }
                } else {
                    if(event == 'answer-call-button-clicked' || event == 'take-off-hold-button-clicked'){
                        this.active_call_id = this.notification.id;
                        this.active_call_contact_name = this.notification.contact_name ? this.notification.contact_name : 'Unknown';
                        this.notification.status = 'active';
                    } else if(event == 'call-back-button-clicked'){
                        this.active_call_id = this.notification.id;
                        this.active_call_contact_name = this.notification.contact_name ? this.notification.contact_name : 'Unknown';
                        this.notification.status = 'call out';
                    }
                }
            },
            afterConfirmation(status){
                if (this.incoming_notifications.length && this.incoming_notifications.some(o => o.status == 'active' || o.status == 'call out')) {
                    this.incoming_notifications.find(o => o.status == 'active' || o.status == 'call out').status = 'hold';
                }

                this.active_call_id = this.notification.id;
                this.active_call_contact_name = this.notification.contact_name ? this.notification.contact_name : 'Unknown';
                this.notification.status = status;
                this.confirmation = '';
            },
            endCall(){
                this.notification.status = 'ended';
                this.active_call_id = '';
                this.active_call_contact_name = '';
            },
            checkManualStatusOverride(){
                if(this.notification.status !== 'active' && this.notification.status !== 'call in' && this.notification.status !== 'call out'){
                    this.active_call_id = '';
                    this.active_call_contact_name = '';
                }
            }
        },
        watch:{
            type(){
                if(this.type == 'mini-charm' && this.notification.status !== 'call out'){
                    this.view = 'default';
                    this.notification.status = 'call in';
                } else if(this.type == 'missed calls' || this.type == 'reservations' || this.type == 'voicemails'){
                    this.view = 'list';
                    this.notification.status = null;
                    this.incoming_notifications = [];
                } else {
                    this.view = 'default';
                }

                if(this.type == 'missed calls'){
                    let clonedArray = this.list_view_items.map(a => {return {...a}});

                    this.list_view_items = clonedArray.map((item) => ({
                        ...item,
                        status: 'missed',
                        communication_type: 'missed',
                        reservation_id: this.generateId(),
                        reservation_space_information: "5' x 5', Climate Controlled",
                        reservation_move_in_date: 'Jun 24, 2022',
                        reservation_code: 'WL9884765',
                        reservation_in_store_price: '$ 160.00',
                        reservation_web_only_price: '$ 148.00',
                    }));
                } else if(this.type == 'reservations'){
                    let clonedArray = this.list_view_items.map(a => {return {...a}});

                    this.list_view_items = clonedArray.map((item) => ({
                        ...item,
                        status: 'reservation',
                        communication_type: 'reservation'
                    }));
                } else if(this.type == 'voicemails'){
                    let clonedArray = this.list_view_items.map(a => {return {...a}});

                    this.list_view_items = clonedArray.map((item) => ({
                        ...item,
                        status: 'voicemail',
                        communication_type: 'voicemail'
                    }));
                }
            },
            'notification.status'(){
                this.title = '';

                if(this.notification.status !== 'active' && this.notification.status !== 'hold'){
                    this.resetDurationTimer();
                    this.resetHoldTimer();
                }
                
                if(this.notification.status == 'call in'){
                    this.notification.communication_type = 'call in';
                } else if(this.notification.status == 'call out'){
                    this.notification.communication_type = 'call out';
                } else if(this.notification.status == 'active'){
                    this.resetHoldTimer();
                    if(this.durationTime == 0){
                        this.startDurationTimer();
                    }
                } else if(this.notification.status == 'hold'){
                    if(this.durationTime == 0){
                        this.startDurationTimer();
                    }
                    this.startHoldTimer();
                } else if(this.notification.status == 'reservation'){
                    this.notification.communication_type = 'reservation';
                    this.notification.reservation_id = this.generateId();
                    this.notification.reservation_space_information = "5' x 5', Climate Controlled";
                    this.notification.reservation_move_in_date = 'Jun 24, 2022';
                    this.notification.reservation_code = 'WL9884765';
                    this.notification.reservation_in_store_price = '$ 160.00';
                    this.notification.reservation_web_only_price = '$ 148.00';
                } else if(this.notification.status == 'missed'){
                    this.notification.communication_type = 'missed';
                } else if(this.notification.status == 'voicemail'){
                    this.notification.communication_type = 'voicemail';
                }
            },
            view(){
                this.title = '';
            },
            "notification.communication_type"(){
                this.communication_title = '';
                this.communication_icon = '';
            },
            count(){
                this.generateListViewItems(this.type == 'missed calls' ? 'missed' : this.type == 'reservations' ? 'reservation' : this.type == 'voicemails' ? 'voicemail' : '');
            },
            voicemailRadioGroup(){
                if(this.voicemailRadioGroup == 3){
                    this.notification.voicemail_url = '';
                } else {
                    this.notification.voicemail_url = this.voicemailRadioGroup;
                }
            },
            'notification.voicemail_url'(){
                if(this.notification.voicemail_url){
                    this.voicemailRadioGroup = this.notification.voicemail_url;
                } else {
                    this.voicemailRadioGroup = 3;
                }
            },
            playbackRadioGroup(){
                if(this.playbackRadioGroup == 3){
                    this.notification.playback_url = '';
                } else {
                    this.notification.playback_url = this.playbackRadioGroup;
                }
            },
            'notification.playback_url'(){
                if(this.notification.playback_url){
                    this.playbackRadioGroup = this.notification.playback_url;
                } else {
                    this.playbackRadioGroup = 3;
                }
            }
        },
    }
</script>

<style scoped>

</style>
